(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("openseadragon"));
	else if(typeof define === 'function' && define.amd)
		define(["openseadragon"], factory);
	else if(typeof exports === 'object')
		exports["Annotorious"] = factory(require("openseadragon"));
	else
		root["OpenSeadragon"] = root["OpenSeadragon"] || {}, root["OpenSeadragon"]["Annotorious"] = factory(root["OpenSeadragon"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__50__) {
return 